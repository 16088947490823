/* eslint-disable react/react-in-jsx-scope */
import { message, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DataTable from '../../../components/DataTable/DataTable.component';
import UserFormModal from '../../../components/UserFromModal/UserFormModal.component';
import {
	DEFAULT_LIMIT_START,
	SEARCH_MIN_CHAR,
	userDataMapped,
	userManagementPermissions,
	userRoleNames,
	userRoles,
} from '../../../constants';
import { databaseDateFormat } from '../../../constants/country';
import { fetchCountriesStartAsync } from '../../../redux/CountryList/CountryList.action';
import { selectAllCountries } from '../../../redux/CountryList/CountryList.selector';
import { selectTranslations } from '../../../redux/i18n/i18nSlice';
import { store } from '../../../redux/store';
import {
	deleteUserListAsync,
	searchUsersStartAsync,
	setUserFilteredInfo,
	showUserFromModal,
} from '../../../redux/User/User.actions';
import {
	selectIsUsersListFetching,
	selectIsUsersSearching,
	selectLoggedInUser,
	selectLoggedInUserRoles,
	selectNumberOfRecord,
	selectUserCountry,
	selectUserFetchError,
	selectUserPerms,
	selectUsersList,
	showUserModalForm,
} from '../../../redux/User/User.selector';
import { ErrorModal } from '../../../utils/errorMessage';
import { getCountryDateFormat } from '../../../utils/GetCountryFormats.utils';
import Sorter from '../../../utils/Sorter.utils';
import { filterUserByUserStatus } from '../../../utils/UserRole.utils';

/* users List component */
function UsersList({
	getCountriesList,
	loggedInUser,
	loggedInUserRoles,
	showUserFormModal,
	users,
	errorMessage,
	isUserFormModal,
	isFetching,
	searchUsersList,
	getNextPage,
	resetUserRoleList,
	deleteUserList,
	userPerms,
	countryCode,
	totalNumberOfRecord,
	countryList,
}) {
	const [searchQuery, setSearchQuery] = useState('');
	/* Date Time format */
	const dateFormat = getCountryDateFormat(countryCode);
	/* use selector for language */
	const t = useSelector(selectTranslations);
	useEffect(() => {
		getCountriesList();
	}, []);
	/* select Record To Edit */
	const selectRecordToEdit = user => {
		if (
			loggedInUser.id === user.id ||
			loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
		) {
			/* show User Form Modal */
			showUserFormModal(user);
		} else {
			ErrorModal('You can not edit the current user');
		}
	};
	/* Render status and change back ground color */
	const renderStatus = status => ({
		/* render status background color */
		props: {
			className:
				status === 'active' ? 'bg-jnj_active text-center' : 'text-center',
		},
		children: <span>{status === 'active' ? 'Active' : 'Inactive'}</span>,
	});
	const getCountryFilter = () => {
		const isAdmin = loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN);
		const adminCountryCodes = countryList
			? countryList
					.filter(({ countryName }) => countryName !== 'ALL')
					.map(country => {
						/* filter as a franchise */
						const filter = { text: '', value: '' };
						filter.text = country.countryName;
						filter.value = country.countryName;
						/* return filter value */
						return filter;
					})
			: [];
		const nonAdminCountryCodes = countryCode
			? countryCode
					.filter(code => code !== 'ALL')
					.map(code => {
						/* filter as a franchise */
						const filter = { text: '', value: '' };
						filter.text = code;
						filter.value = code;
						/* return filter value */
						return filter;
					})
			: [];
		return !isAdmin ? nonAdminCountryCodes : adminCountryCodes;
	};

	/* User list columns */
	const userColumns = [
		/* user First Name */
		{
			title: t.FirstName,
			dataIndex: 'firstName',
			key: 'first_name',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: userFirstName => (
				<Tooltip placement="topLeft" title={userFirstName}>
					{userFirstName}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: (a, b) => Sorter.DEFAULT(a.userFirstName, b.userFirstName),
		},
		/* user Last Name */
		{
			title: t.LastName,
			dataIndex: 'lastName',
			key: 'last_name',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: userLastName => (
				<Tooltip placement="topLeft" title={userLastName}>
					{userLastName}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: (a, b) => Sorter.DEFAULT(a.userLastName, b.userLastName),
		},
		/* user Name */
		{
			title: t.Username,
			dataIndex: 'email',
			key: 'email',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: userName => (
				<Tooltip placement="topLeft" title={userName}>
					{userName}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: (a, b) => Sorter.DEFAULT(a.userName, b.userName),
		},
		/* user Role */
		{
			title: t.UserRole,
			dataIndex: 'roles',
			key: 'roles',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: (userRole, user) => (
				<Tooltip key={user.key} placement="topLeft" title={userRole}>
					{userRole
						? userRole.map(item => (
								<div key={item}>{`${userRoleNames[item]},`}</div>
						  ))
						: null}
				</Tooltip>
			),
		},
		/* user Status */
		{
			title: t.Status,
			dataIndex: 'status',
			key: 'status',
			filters: [
				{ text: 'Active', value: 'active' },
				{ text: 'Inactive', value: 'inactive' },
			],
			width: 200,
			render: userActive => renderStatus(userActive),
			onFilter: (value, record) =>
				record.status ? record.status.indexOf(value) === 0 : '',
		},
		/* last Login */
		{
			title: t.LastLogin,
			dataIndex: 'lastLogin',
			key: 'lastLogin',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: userLastLogin => (
				<Tooltip
					placement="topLeft"
					title={moment(userLastLogin, databaseDateFormat).format(dateFormat)}>
					{userLastLogin
						? moment(userLastLogin, databaseDateFormat).format(dateFormat)
						: ''}
				</Tooltip>
			),
		},
		/* Phone number */
		{
			title: t.PhoneNumber,
			dataIndex: 'phoneNumber',
			key: 'phone_number',
			width: 200,
			render: userPhoneNumber => userPhoneNumber,
		},
		/* Phone number */
		{
			title: t.Country,
			dataIndex: 'country',
			key: 'country',
			width: 200,
			render: country => country.join(','),
			filters: getCountryFilter(),
		},
	];

	const getCountryToFIlter = () =>
		!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
			? countryCode.join(' ')
			: '';
	const handleTableChange = (pagination, filter, sorter) => {
		store.dispatch(setUserFilteredInfo(filter));
		getNextPage(
			(pagination.current - 1) * pagination.pageSize,
			pagination.pageSize,
			searchQuery,
			sorter.columnKey,
			sorter.order,
			filter.country ? filter.country.join(' ') : getCountryToFIlter(),
			filterUserByUserStatus(filter.status),
		);
	};

	const handleDelete = data => {
		if (loggedInUser.id === data.id) {
			ErrorModal('You can not delete the current user');
		} else if (
			((data.roles.includes(userRoles.CONSIGNMENT_LEAD) ||
				data.roles.includes(userRoles.SYSTEM_ADMIN)) &&
				userPerms &&
				!userPerms.includes(userManagementPermissions.CONSIGNMENT_LEAD_CRUD)) ||
			((data.roles.includes(userRoles.CONSIGNMENT_ANALYST) ||
				data.roles.includes(userRoles.SYSTEM_ADMIN)) &&
				userPerms &&
				!userPerms.includes(userManagementPermissions.CONSIGNMENT_ANALYST_CRUD))
		) {
			ErrorModal('You can not delete a user with the same role or above');
		} else {
			deleteUserList(data, loggedInUser.id);
		}
	};
	/* Render */
	return (
		<div>
			{errorMessage ? message.error(errorMessage) : null}
			<DataTable
				columnsInit={userColumns.map(column =>
					column.title
						? {
								...column,
								title: (
									<div className="flex font-bold  justify-between">
										<div className="w-4/5 whitespace-normal">
											{column.title}
										</div>
									</div>
								),
						  }
						: column,
				)}
				totalRecords={totalNumberOfRecord}
				exportHeaders={Array(userColumns.map(column => column.title))}
				callerComponent="Users List"
				data={
					users.length
						? users.map(user => ({ ...user, key: `tooltip_${user.id}` }))
						: null
				}
				perPage={DEFAULT_LIMIT_START}
				columnsOption={userColumns.map(column => ({
					label: column.title,
					value: column.dataIndex,
				}))}
				createUser={
					loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
						? showUserFormModal
						: false
				}
				searchData={key => {
					const filteredInfo = store.getState().user.userFilteredInfo;
					searchUsersList(
						key,
						!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
							? countryCode.join(' ')
							: '',
						filterUserByUserStatus(filteredInfo?.status),
					);
					setSearchQuery(key);
				}}
				resetData={() => {
					const filteredInfo = store.getState().user.userFilteredInfo;
					resetUserRoleList(
						getCountryToFIlter(),
						filterUserByUserStatus(filteredInfo?.status),
					);
					setSearchQuery('');
				}}
				selectRecordToEdit={selectRecordToEdit}
				mappedData={userDataMapped}
				csvData={
					totalNumberOfRecord > 0
						? users.map(({ id, ...keepAttrs }) => keepAttrs)
						: null
				}
				isFetching={isFetching}
				handleTableChange={handleTableChange}
				deleteData={
					loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
						? e => handleDelete(e)
						: false
				}
			/>

			{isUserFormModal ? <UserFormModal /> : null}
		</div>
	);
}
/* mapStateToProps */
const mapStateToProps = createStructuredSelector({
	loggedInUser: selectLoggedInUser,
	users: selectUsersList,
	userPerms: selectUserPerms,
	isUserFormModal: showUserModalForm,
	errorMessage: selectUserFetchError,
	isSearching: selectIsUsersSearching,
	isFetching: selectIsUsersListFetching,
	loggedInUserRoles: selectLoggedInUserRoles,
	countryCode: selectUserCountry,
	totalNumberOfRecord: selectNumberOfRecord,
	countryList: selectAllCountries,
});
/* mapDispatchToProps */
const mapDispatchToProps = dispatch => ({
	/* show User Modal */
	showUserFormModal: user => dispatch(showUserFromModal(user)),
	/* search Users */
	searchUsersList: (query, country, filteredInfo) => {
		if (query.length > SEARCH_MIN_CHAR)
			dispatch(searchUsersStartAsync(country, query, filteredInfo));
	},
	/* get Next Page */
	getNextPage: (
		offset,
		limit,
		query,
		sortKey,
		sortOrder,
		country,
		userStatus,
	) =>
		dispatch(
			searchUsersStartAsync(
				country,
				query,
				userStatus,
				offset,
				limit,
				sortKey,
				sortOrder,
			),
		),
	/* reset search input  */
	resetUserRoleList: (country, filteredInfo) =>
		dispatch(searchUsersStartAsync(country, '', filteredInfo)),
	deleteUserList: (data, loginId) =>
		dispatch(deleteUserListAsync(data, loginId)),
	getCountriesList: () => dispatch(fetchCountriesStartAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
